import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Button } from '@aristech/components'
import styled from 'styled-components'

const Success = styled.span`
  color: rgb(140, 197, 118);
`

const DeactivateGoogleAnalyticsButton: React.FC = () => {
  const intl = useIntl()
  const [deactivate, setDeactivate] = React.useState(false)

  if (deactivate) {
    return (
      <Success>
        {intl.formatMessage({ id: 'deactivate_google_analytics_success' })}
      </Success>
    )
  }

  return (
    <Button
      onClick={() => {
        try {
          ;(window as any).gaOptOut()
          console.log('Google Analytics disabled...')
          setDeactivate(true)
        } catch (err) {}
      }}
    >
      {intl.formatMessage({ id: 'deactivate_google_analytics' })}
    </Button>
  )
}

export default DeactivateGoogleAnalyticsButton

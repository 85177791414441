import React from 'react'
import loadable from '@loadable/component'
import { CircleLoader } from '@aristech/components'
import ErrorBoundary from '../ErrorBoundary'
import Fallback from './Fallback'
import Props from './Props'

const Audio = loadable(() => import('./Audio'), {
  fallback: <CircleLoader as="span" />,
})

const LoadableAudio: React.FC<Props> = (props: Props) => {
  const { src, alt } = props
  if (typeof window === 'undefined') {
    // WaveSurfer uses window to get devicePixelRatio for example
    // therefore when rendering server side we don't render anything for audios
    return null
  }
  return (
    <ErrorBoundary fallback={<Fallback src={src} alt={alt} />}>
      <Audio src={src} alt={alt} />
    </ErrorBoundary>
  )
}

export default LoadableAudio

import React from 'react'
import MarkdownRenderer from 'react-markdown'
import Link from '../Link'
import Audio from '../Audio'
import styled from 'styled-components'
import { AudioSource } from '../Audio/Props'
import DeactivateGoogleAnalyticsButton from '../DeactivateGoogleAnalyticsButton'

interface Props {
  source: string
  className?: string
}

const MarkdownEl = styled(MarkdownRenderer)``

interface LinkProps {
  href: string
  children: string
}
const linkRenderer = ({ href, children }: LinkProps) => (
  <Link to={href}>{children}</Link>
)

const inlineCodeRenderer = ({ value }: { value: string }) => {
  if (value === 'disable-google-analytics') {
    return <DeactivateGoogleAnalyticsButton />
  }
  return <i className={`flaticon-${value}`} />
}

const wavRe = /\.wav$/

const imageRenderer = (props: { src: string }) => {
  const { src } = props
  // console.log('Src', src)
  if (wavRe.test(src)) {
    const sources: [AudioSource] = [
      {
        src: src.replace(wavRe, '.m4a'),
        type: 'audio/mp4',
      },
    ]
    sources.push({
      src: src.replace(wavRe, '.ogg'),
      type: 'audio/ogg; codecs=vorbis',
    })
    sources.push({ src: src.replace(wavRe, '.flac'), type: 'audio/flac' })
    sources.push({ src, type: 'audio/wav' })
    return <Audio src={sources} />
    // return <audio {...props} controls />
  }
  return <img {...props} />
}

const Markdown: React.FC<Props> = (props: Props) => {
  const { source, className } = props
  const classNames = ['md']
  if (className) {
    classNames.push(className)
  }
  // escapeHtml={false}
  // source={source}
  return (
    <MarkdownEl
      renderers={{
        link: linkRenderer,
        inlineCode: inlineCodeRenderer,
        image: imageRenderer,
      }}
      className={classNames.join(' ')}
      allowDangerousHtml
    >
      {source}
    </MarkdownEl>
  )
}

export default React.memo(Markdown)

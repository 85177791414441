import React from 'react'
import Props from './Props'

const Fallback: React.FC<Props> = (props: Props) => {
  const { src: srcs, alt } = props
  return (
    <audio controls aria-label={alt}>
      {srcs.map(({ src, type }) => (
        <source type={type} src={src} />
      ))}
    </audio>
  )
}

export default Fallback
